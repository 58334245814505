import React, { MouseEventHandler } from 'react'
import { TextNavAnchor } from 'atoms/links'
import logoMobile from 'assets/globals/logo_svg.svg'
import { Disclosure, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import ResourceRoutes from 'molecules/resourceRoutes'
import BalloonRoutes from 'molecules/balloonRoutes'
import StentRoutes from 'molecules/stentRoutes'
import CathLabRoutes from 'molecules/cathLabRoutes'
import OtherRoutes from 'molecules/otherRoutes'
import SocialProfile from 'molecules/socialProfile'

export default function MobileNavModal({
  toggle,
}: {
  toggle: React.DispatchWithoutAction
}) {
  const navigate = useNavigate()

  return (
    <section className="bg-white min-h-screen flex flex-col">
      <div className="px-3 py-5 flex gap-3  justify-between flex-0">
        <div>
          <img
            src={logoMobile}
            className="h-12 w-auto object-contain object-center"
            alt="Logo of Innvolution"
          />
        </div>

        <button
          onClick={toggle}
          className="p-3 bg-white bg-opacity-40 outline-none   header-shadow rounded-full header-shadow stroke-blue"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="inherit"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-col flex-1 gap-1">
        <div className="flex-1 flex flex-col justify-between h-full">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <ul className="flex flex-col gap-3 px-3 pt-8" onKeyDown={toggle}>
            <li onClick={toggle}>
              <TextNavAnchor
                size="large"
                href="/who-we-are"
                text="Who we are"
              />
            </li>

            <Disclosure>
              {({ open, close }) => (
                <>
                  <Disclosure.Button className="justify-between pr-2  flex items-center gap-1 text-left hover:underline font-regular outline-none whitespace-nowrap focus-visible:underline underline-offset-4 decoration-from-font text-opacity-80 transition-colors duration-300 ease-in-out focus-visible:text-opacity-100 hover:text-opacity-100 text-black text-base">
                    Products
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      className={` ${
                        open ? 'rotate-180' : ''
                      } transition-all duration-300 ease-in-out w-4 h-4`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </Disclosure.Button>

                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform opacity-0"
                    enterTo="transform opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform  opacity-100"
                    leaveTo="transform  opacity-0 "
                  >
                    <Disclosure.Panel
                      static
                      className="bg-lightgray bg-opacity-30 py-2 px-1"
                      // className="bg-lightgray bg-opacity-30 py-2 px-1 grid grid-cols-2 gap-y-5"
                    >
                      <div className="basis-60 grow-0 shrink p-2 pt-3 gap-1">
                        <CathLabRoutes
                          onClose={toggle}
                          onCloseMob={close}
                          isColumnView
                        />
                      </div>
                      <div className="basis-60 grow-0 row-span- shrink flex flex-col p-2 pt-3 gap-1">
                        <StentRoutes
                          onClose={toggle}
                          onCloseMob={close}
                          isColumnView
                        />
                      </div>
                      <div className="basis-full grow0 col-span-2 flex-shrink-0 flex flex-col  p-2 pt-3 gap-1">
                        <BalloonRoutes
                          onClose={toggle}
                          onCloseMob={close}
                          isColumnView
                        />
                      </div>
                      <div className="basis-60 grow-0 p-2 pt-3 shrink  flex flex-col gap-0 items-start justify-start">
                        <OtherRoutes onClose={toggle} onCloseMob={close} />
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>

            <span onClick={toggle}>
              <TextNavAnchor
                size="large"
                href="/services-and-support"
                text="Services & support"
              />
            </span>
            <span onClick={toggle}>
              <TextNavAnchor
                size="large"
                href="/contact-us"
                text="Contact us"
              />
            </span>
            <span onClick={toggle}>
              <TextNavAnchor
                size="large"
                href="/innovation"
                text="Innovation"
              />
            </span>
            {/* <li onClick={toggle}>
              <TextNavAnchor size="large" href="resources" text="Resources" />
            </li> */}
            <Disclosure>
              {({ open, close }) => (
                <>
                  <Disclosure.Button className="justify-between pr-2  flex items-center gap-1 text-left hover:underline font-regular outline-none whitespace-nowrap focus-visible:underline underline-offset-4 decoration-from-font text-opacity-80 transition-colors duration-300 ease-in-out focus-visible:text-opacity-100 hover:text-opacity-100 text-black text-base">
                    Resources
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      className={` ${
                        open ? 'rotate-180' : ''
                      } transition-all duration-300 ease-in-out w-4 h-4`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </Disclosure.Button>

                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform opacity-0"
                    enterTo="transform opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform  opacity-100"
                    leaveTo="transform  opacity-0 "
                  >
                    <Disclosure.Panel
                      static
                      className="bg-lightgray bg-opacity-30 py-2 px-1 grid grid-cols "
                    >
                      <div className="basis-60 grow-0 shrink p-2 gap-1">
                        <div className="flex flex-col gap-1 items-start">
                          <ResourceRoutes onClose={toggle} onCloseMob={close} />
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </ul>
          <div className="pb-20">
            <SocialProfile classes="gap-x-5 items-center justify-center " />
          </div>
        </div>
      </div>
    </section>
  )
}
