import React from 'react'
import Benefits from 'organisms/benefits'

const BenefitsNC = () => {
  return (
    <Benefits
      bgGrad="linear-gradient(0deg, #FAF8FF 0%, #FAF8FF 100%), #FFF"
      title="Catheter for excellent deliverability"
    />
  )
}

export default BenefitsNC
