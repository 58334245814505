export const homeRequestURLs = {
  SECTION_2: '/home/section-2/',
  SECTION_3: '/home/section-3/',
  SECTION_4: '/home/section-4/',
  SECTION_7: '/home/section-7/',
  SECTION_8: '/home/section-8/',
  // SECTION_9: '/home/section-9/',
  SECTION_10: '/home/section-10/',
  SECTION_11: '/home/section-11/',
  EVENTPOPUP: "/home/popup/",
  BANNER: "/home/banners/"
}

export const cathLabRequestURLs = {
  SECTION_5: '/cath-lab/section-5/',
  SECTION_6: '/cath-lab/section-6/',
}

export const pinnacleAgileURLs = {
  SECTION_5: '/pinnacle-agile/section-5/',
}

export const awardRequestURLs = {
  SECTION_2: '/award/section-2/',
}

export const aboutRequestURLs = {
  SECTION_5: '/who-we-are/section-5/',
  SECTION_6: '/who-we-are/section-6/',
  // SECTION_8_Image: '/who-we-are/section-8/image',
}

export const eliteRequestURLs = {
  SECTION_6: '/premier-elite/section-6/',
  SECTION_8: '/premier-elite/section-8/',
}

export const selectRequestURLs = {
  SECTION_8: '/premier-select/section-8/',
}

export const careersRequestURLs = {
  SECTION_4: '/careers/section-4/',
}

export const newsRequestURLs = {
  SECTION_2: '/news-and-articles/section-2/',
}

export const contactRequestURLs = {
  SECTION_3_Manufacturing: '/contact-us/section-3/manufacturing-details/',
  SECTION_3_Office: '/contact-us/section-3/office-details/',
  SECTION_3_RD: '/contact-us/section-3/rd-details/',
  SECTION_3_Image: '/contact-us/section-3/image/',
}

export const defaultCardAction = {
  _id: '',
  isEdit: false,
  editableContentIndex: 0,
  isAdd: false,
  isDelete: false,
}
