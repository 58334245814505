import React from 'react'

export default function Banner() {
  return (
    <div className="bg-[#371101] bg-[url('assets/pinnaclePro/banner/mob-original.png')] min-[500px]:bg-[url(assets/pinnaclePro/banner/desk.jpg)] h-[650px] bg-[60%_50%] min-[500px]:bg-center md:bg-[url(assets/pinnaclePro/banner/desk.jpg)] md:h-[115vh] md:min-h-[700px] bg-cover md:bg-center xl:bg-bottom lg:min-h-[750px] xl:min-h-[800px] 2xl:bg-center bg-no-repeat">
      <div className="flex items-end blade-bottom-padding lg:blade-bottom-padding-sm h-full">
        <div className="flex flex-col md:flex-row w-container md:items-center md:justify-center gap-y-4">
          <div className="w-fit">
            {/* <div className="basis-[20rem] lg:basis-[20rem] xl:basis-[20rem] 2xl:basis-[22rem] flex-col grow-0 shrink flex  items-start justify-center gap-5"> */}
            <div className="h-12 md:h-14 lg:h-16 p-1 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-full w-auto 2xl:h-20"
                viewBox="0 0 196 98"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M93.5702 65.8411L88.8947 59.8506C84.0731 59.8506 67.4166 60.8734 64.9327 59.2662C63.0333 57.9512 59.5267 53.714 56.8967 53.714C53.244 53.5679 49.299 59.8506 44.9157 59.9967L0.644531 60.1428V61.6039H2.39785L44.6235 61.4578C49.4451 61.4578 52.8056 55.1751 56.7506 55.1751C60.8417 55.1751 63.1794 61.75 68.1471 61.75L88.018 61.4578L94.8852 69.2016L106.574 30.4826L119.578 75.6304L126.007 61.6039C127.029 61.75 128.198 61.6039 128.929 61.4578C134.919 60.5812 134.773 55.6134 143.101 55.6134C148.946 55.6134 154.206 62.6267 159.612 62.6267L195.701 62.9189V61.4578L159.32 61.3117C153.329 60.8734 149.53 54.4446 143.247 54.4446C134.189 54.4446 133.896 60.2889 126.883 60.2889C122.938 60.2889 129.221 60.2889 125.13 60.2889L120.162 70.6627L106.574 23.031L93.5702 65.8411Z"
                  fill="white"
                />
                <path
                  d="M86.414 40.7093C87.729 40.7093 87.8751 39.6866 87.8751 39.1021C87.8751 39.1021 87.4368 39.3943 86.1218 39.3943H81.3002V35.3033H87.729C88.7518 35.3033 88.7518 33.9883 88.7518 33.9883H79.5469V46.4076C79.5469 47.4304 81.3002 47.4304 81.3002 47.4304H87.729C88.7518 47.4304 88.7518 46.1154 88.7518 46.1154H81.3002V40.8554H86.414V40.7093Z"
                  fill="white"
                />
                <path
                  d="M77.6446 47.2843C78.5212 47.2843 78.6673 46.2615 78.6673 45.677C78.6673 45.677 78.229 45.9693 76.914 45.9693H71.8002V34.8649C71.8002 33.9883 70.0469 33.9883 70.0469 33.9883V46.4076C70.0469 47.2843 71.8002 47.2843 71.8002 47.2843H77.6446Z"
                  fill="white"
                />
                <path
                  d="M62.1608 35.3039C64.2063 35.3039 66.8363 36.0344 66.8363 37.4955C68.5896 34.5733 64.9369 33.8428 62.1608 33.8428C59.2386 33.8428 56.3164 35.0117 56.3164 38.08V42.9016C56.3164 46.116 59.0925 47.1387 62.1608 47.1387C64.9369 47.1387 68.5896 46.4082 66.8363 43.486C66.8363 45.0932 64.2063 45.6776 62.1608 45.6776C59.823 45.6776 58.0697 44.9471 58.0697 42.4632V38.2261C58.0697 36.0344 59.823 35.3039 62.1608 35.3039Z"
                  fill="white"
                />
                <path
                  d="M32.9362 34.5735C32.3518 33.6969 31.329 33.6969 30.1602 33.6969V46.1162C30.1602 46.9929 31.9135 46.9929 31.9135 46.9929V37.2035C31.9135 36.473 31.7674 35.4502 31.7674 35.4502L38.6345 45.6779C38.6345 45.6779 39.2189 46.8467 41.4106 46.8467V34.4274C41.4106 33.5508 39.6573 33.5508 39.6573 33.5508V43.3401C39.6573 43.9246 39.8034 44.9473 39.8034 44.9473L38.7806 43.4862L32.9362 34.5735Z"
                  fill="white"
                />
                <path
                  d="M19.0573 34.5735C18.4729 33.6969 17.4501 33.6969 16.2812 33.6969V46.1162C16.2812 46.9929 18.0346 46.9929 18.0346 46.9929V37.2035C18.0346 36.473 17.8885 35.4502 17.8885 35.4502L24.7556 45.6779C24.7556 45.6779 25.34 46.8467 27.5317 46.8467V34.4274C27.5317 33.5508 25.7784 33.5508 25.7784 33.5508V43.3401C25.7784 43.9246 25.9245 44.9473 25.9245 44.9473L24.9017 43.4862L19.0573 34.5735Z"
                  fill="white"
                />
                <path
                  d="M11.4609 46.4076C11.4609 47.2843 13.2143 47.2843 13.2143 47.2843V34.8649C13.2143 33.9883 11.4609 33.9883 11.4609 33.9883V46.4076Z"
                  fill="white"
                />
                <path
                  d="M4.15274 33.6965H0.5V46.1158C0.5 46.9925 2.25332 46.9925 2.25332 46.9925V42.317H4.15274C7.22104 42.317 9.85101 41.4403 9.85101 38.0798V37.7876C9.85101 34.4271 7.22104 33.6965 4.15274 33.6965ZM4.15274 41.002H2.25332V34.8654H4.15274C6.49049 34.8654 8.0977 35.4498 8.0977 37.9337C8.0977 40.5637 6.49049 41.002 4.15274 41.002Z"
                  fill="white"
                />
                <path
                  d="M51.6384 42.9014H46.3785L45.2096 45.8236C45.0635 46.4081 44.7713 46.7003 44.479 46.8464C44.1868 46.9925 43.7485 46.9925 43.1641 46.9925L47.6935 35.1576C47.9857 34.5732 48.1318 34.1349 48.2779 33.9887C48.424 33.8426 48.7162 33.6965 49.0084 33.6965C49.3007 33.6965 49.5929 33.8426 49.739 33.9887C49.8851 34.1349 50.0312 34.5732 50.3234 35.1576L54.8528 46.9925C54.2684 46.9925 53.8301 46.9925 53.5378 46.8464C53.2456 46.7003 53.0995 46.4081 52.8073 45.8236L51.6384 42.9014ZM51.054 41.5865L49.0084 35.8882L46.8168 41.5865H51.054Z"
                  fill="white"
                />
                <path
                  d="M132.44 38.0799L130.54 47.2848H128.933C128.056 47.2848 127.326 47.1387 127.034 46.8465C126.595 46.5542 126.449 45.9698 126.449 45.2393C126.449 44.8009 126.595 44.2165 126.741 43.4859L131.125 22.5923H140.622C143.106 22.5923 145.005 23.0306 146.32 24.0534C147.635 25.0761 148.22 26.5372 148.22 28.4367C148.22 31.505 147.197 33.9888 145.297 35.596C143.398 37.2032 140.622 38.0799 137.261 38.0799H132.44ZM134.924 26.5372L133.316 34.1349H138.138C139.891 34.1349 141.206 33.6966 142.083 32.82C143.106 31.9433 143.544 30.7744 143.544 29.0211C143.544 28.1445 143.252 27.4139 142.667 26.9756C142.083 26.5372 141.06 26.3911 139.891 26.3911H134.924V26.5372Z"
                  fill="white"
                />
                <path
                  d="M155.375 37.3493L153.329 47.2848H151.722C150.846 47.2848 150.115 47.1387 149.823 46.8465C149.384 46.5542 149.238 45.9698 149.238 45.2393C149.238 44.8009 149.384 44.2165 149.531 43.4859L153.914 22.5923H163.411C166.041 22.5923 167.94 23.0306 169.255 24.0534C170.57 25.0761 171.155 26.5372 171.155 28.2906C171.155 30.4822 170.57 32.3816 169.255 33.9888C167.94 35.4499 166.187 36.4727 163.995 36.911L167.648 47.1387C167.064 47.1387 166.625 47.1387 166.041 47.1387C164.872 47.1387 163.995 46.9926 163.411 46.7004C162.826 46.4081 162.388 45.8237 162.096 45.0932L159.32 37.3493H155.375ZM157.567 26.5372L156.105 33.5505H160.927C164.434 33.5505 166.187 32.0894 166.187 29.0211C166.187 28.1445 165.895 27.56 165.31 27.1217C164.726 26.6834 163.703 26.5372 162.388 26.5372H157.567Z"
                  fill="white"
                />
                <path
                  d="M172.473 38.5186C172.473 36.9114 172.619 35.3042 173.057 33.8431C173.495 32.2359 174.08 30.7748 174.81 29.4598C175.541 28.1448 176.564 26.8298 177.586 25.807C178.755 24.7843 180.07 23.9076 181.531 23.3232C183.139 22.7388 184.746 22.4465 186.499 22.4465C189.567 22.4465 191.905 23.3232 193.512 24.9304C195.12 26.5376 195.996 28.7292 195.996 31.6514C195.996 33.2586 195.85 34.8658 195.412 36.3269C194.974 37.9341 194.389 39.3952 193.659 40.7102C192.928 42.0252 191.905 43.3402 190.882 44.363C189.714 45.3857 188.399 46.2624 186.937 46.8468C185.33 47.4313 183.723 47.7235 181.97 47.7235C178.901 47.7235 176.564 46.8468 174.957 45.2396C173.203 43.4863 172.473 41.2947 172.473 38.5186ZM182.262 43.6324C183.431 43.6324 184.454 43.3402 185.476 42.9019C186.499 42.4635 187.23 41.733 187.96 41.0024C188.691 40.2719 189.275 39.2491 189.714 38.2264C190.152 37.2036 190.59 36.1808 190.736 35.012C191.029 33.8431 191.029 32.8203 191.029 31.7975C191.029 29.8981 190.59 28.5831 189.714 27.5604C188.837 26.5376 187.522 26.0993 185.915 26.0993C184.746 26.0993 183.723 26.3915 182.7 26.8298C181.678 27.2681 180.947 27.9987 180.216 28.7292C179.486 29.4598 178.901 30.4826 178.463 31.5053C178.025 32.5281 177.586 33.5508 177.44 34.7197C177.148 35.8886 177.148 36.9114 177.148 37.9341C177.148 39.8336 177.586 41.1486 178.463 42.1713C179.34 43.1941 180.655 43.6324 182.262 43.6324Z"
                  fill="white"
                />
              </svg>
            </div>
            {/* <h2
              style={{
                backgroundImage:
                  'linear-gradient(186deg, #FFF -96.64%, #F69A4D 109.37%)',
              }}
              className="bg-clip-text text-transparent font-medium whitespace-nowrap pr-8 lg:hidden"
            >
              Pinnacle Agile
            </h2> */}
            <h3
              style={{
                backgroundImage:
                  'linear-gradient(186deg, #FFF -96.64%, #F69A4D 109.37%)',
              }}
              className="bg-clip-text text-transparent font-medium whitespace-nowrap pr-8 hidde lg:block"
            >
              Pinnacle Pro
            </h3>
          </div>
          <div className="w-[5px] mr-[3rem] h-auto self-stretch hidden md:block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              className="h-full w-auto object-contain"
              viewBox="0 0 8 126"
              fill="none"
            >
              <g filter="url(#filter0_f_1099_14363)">
                <rect
                  x="2"
                  y="2.72168"
                  width="4"
                  height="121"
                  fill="url(#paint0_linear_1099_14363)"
                />
              </g>
              <defs>
                <filter
                  id="filter0_f_1099_14363"
                  x="0"
                  y="0.72168"
                  width="8"
                  height="125"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="1"
                    result="effect1_foregroundBlur_1099_14363"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_1099_14363"
                  x1="4"
                  y1="2.72168"
                  x2="41.2978"
                  y2="8.69574"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFE9D7" />
                  <stop offset="1" stopColor="#F69A4D" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div className="flex text-white flex-col gap-3 items-start w-fit ">
            <h5 className="font-regular tracking-wide text-white leading-snug lg:max-w-sm md:hidden">
              Most affordable fixed flat-panel Cath Lab in the world
            </h5>
            <h4 className="font-regular hidden md:block tracking-wide text-white leading-snug max-w-[24rem] lg:max-w-[26rem] 2xl:max-w-[30rem]">
              Most affordable fixed flat-panel Cath Lab in the world
            </h4>
            <div className="flex gap-y-1 flex-col md:flex-row gap-x-4 md:divide-x-1 font-normal font-regular divide-white text-[14px] md:text-[16px] 2xl:text-[18px] ">
              <span>65 kW high-frequency X-ray generator </span>
              <span className="md:pl-4">1.5 MHU X-ray tube</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

//  <div className="flex text-white flex-col gap-3 items-start w-full ">
//             <h4 className="font-regular text-white leading-snug max-w-lg">
//               Most affordable fixed flat-panel Cath Lab in the world
//             </h4>
//             <ol className=" font-regular pl-7 list-disc flex flex-col gap-1">
//               <li>65 kW high-frequency X-ray generator | 1.5 MHU X-ray tube</li>
//               <li>1.5 MHU X-ray tube</li>
//             </ol>
//           </div>
//         </div>
