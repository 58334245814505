const classesSVG =
  'h-7 w-7 fill-black hover:fill-orange group-focus-visible:fill-orange transition-colors duration-200 rounded-md overflow-hidden'

const profiles = [
  {
    link: process.env.REACT_APP_LINKEDIN,
    icon: (
      <svg
        viewBox="0 0 90 90"
        fill="inherit"
        className={classesSVG}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.655 13.829C12.194 15.443 12 19.112 12 45.174C12 72.499 12.136 74.813 13.829 76.345C15.443 77.806 19.112 78 45.174 78C72.499 78 74.813 77.864 76.345 76.171C77.806 74.557 78 70.888 78 44.826C78 17.501 77.864 15.187 76.171 13.655C74.557 12.194 70.888 12 44.826 12C17.501 12 15.187 12.136 13.655 13.829ZM24.949 25.051C21.717 28.283 23.835 32.949 28.55 32.985C32.777 33.016 34.586 27.729 31.429 24.571C29.31 22.453 27.406 22.594 24.949 25.051ZM49.635 39.073C47.451 40.791 47 40.876 47 39.573C47 38.405 45.971 38 43 38H39V52V66H43H47V57.191C47 51.447 47.462 47.75 48.328 46.566C49.89 44.43 54.846 44.148 56.098 46.125C56.577 46.881 56.976 51.663 56.985 56.75L57 66H61.5H66V55.968C66 44.427 64.493 39.518 60.483 37.994C56.38 36.434 52.499 36.82 49.635 39.073ZM24 52V66H28.5H33V52V38H28.5H24V52Z"
          fill="inherit"
        />
      </svg>
    ),
    text: 'Linkedin',
  },
  {
    link: process.env.REACT_APP_INSTA,
    icon: (
      <svg
        viewBox="0 0 90 90"
        fill="inherit"
        className={classesSVG}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5 10.8959C19.3 11.9139 16.286 14.0419 14.803 15.6239C9.61 21.1619 9 24.2489 9 44.9999C9 65.7509 9.61 68.8379 14.803 74.3759C20.325 80.2649 23.497 80.9559 45 80.9559C66.503 80.9559 69.675 80.2649 75.197 74.3759C80.39 68.8379 81 65.7509 81 44.9999C81 24.2489 80.39 21.1619 75.197 15.6239C69.675 9.73495 66.503 9.04395 45 9.04395C28.126 9.04395 24.961 9.29295 21.5 10.8959ZM63.958 22.5499C62.896 23.8309 62.961 24.3899 64.336 25.7639C65.888 27.3169 66.112 27.3169 67.664 25.7639C69.039 24.3899 69.104 23.8309 68.042 22.5499C67.334 21.6979 66.415 20.9999 66 20.9999C65.585 20.9999 64.666 21.6979 63.958 22.5499ZM37.168 29.0889C25.005 35.2699 23.859 52.2459 35.103 59.6869C50.079 69.5979 68.782 53.5759 61.001 37.5019C56.601 28.4129 45.919 24.6419 37.168 29.0889ZM38.873 34.6839C31.098 39.5089 31.189 50.7269 39.04 55.3639C46.781 59.9369 57 54.0399 57 44.9999C57 35.9389 46.489 29.9569 38.873 34.6839Z"
          fill="inherit"
        />
      </svg>
    ),
    text: 'Instagram',
  },
  {
    link: process.env.REACT_APP_TWITTER,
    icon: (
      <svg
        viewBox="0 0 90 90"
        fill="inherit"
        className={classesSVG}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6142 27.7499C29.5042 36.4129 34.9792 44.2709 35.7822 45.2129C36.5862 46.1549 37.0132 47.5049 36.7332 48.2129C36.4532 48.9209 31.3362 55.1509 25.3622 62.0569C19.3882 68.9629 13.8822 75.3759 13.1282 76.3069C11.8812 77.8439 12.1242 77.9999 15.7612 77.9999C19.6412 77.9999 20.0942 77.6199 30.0832 65.9999C35.7562 59.3999 40.6952 53.9999 41.0592 53.9999C41.4222 53.9999 45.4092 59.3999 49.9182 65.9999L58.1162 77.9999H68.6172C75.8062 77.9999 78.9102 77.6629 78.4582 76.9319C78.0952 76.3449 72.2032 67.7359 65.3632 57.8009C54.5242 42.0569 53.1002 39.5299 54.2732 38.1189C55.0132 37.2279 59.8912 31.5499 65.1122 25.4999C77.2872 11.3939 76.9682 11.9999 72.2212 11.9999C68.4942 11.9999 67.9202 12.4769 59.0002 22.9999C53.8712 29.0499 49.5512 33.9999 49.3992 33.9999C49.2472 33.9999 45.6932 29.0499 41.5002 22.9999L33.8772 11.9999H23.3922H12.9072L23.6142 27.7499ZM25.1672 18.7819C25.3502 19.2119 33.5892 31.3619 43.4762 45.7819C59.9172 69.7599 61.6972 71.9999 64.3102 71.9999C65.8812 71.9999 67.0172 71.6229 66.8332 71.1619C66.6502 70.7009 58.4232 58.5509 48.5502 44.1619C32.0722 20.1449 30.3642 17.9999 27.7172 17.9999C26.1312 17.9999 24.9832 18.3519 25.1672 18.7819Z"
          fill="inherit"
        />
      </svg>
    ),
    text: 'Twitter',
  },
  {
    link: process.env.REACT_APP_FACEBOOK,
    icon: (
      <svg
        viewBox="0 0 90 90"
        fill="inherit"
        className={classesSVG}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.1482 10.938C13.0452 18.393 3.39917 41.264 12.3812 60.178C15.8132 67.406 25.0442 76.145 31.6082 78.382C40.3802 81.371 40.0982 81.755 39.7802 67.25L39.5002 54.5L35.2502 54.193L31.0002 53.885V49.443V45H35.5002H40.0002V39.468C40.0002 28.197 44.6372 23.581 55.3362 24.201L60.5002 24.5L60.8102 28.25C61.1122 31.896 61.0282 32 57.7832 32C52.2302 32 50.0002 34.308 50.0002 40.057V45H55.0002C59.8582 45 60.0002 45.083 60.0002 47.918C60.0002 53.083 59.1552 54 54.3932 54H50.0002V67.395V80.789L53.2502 80.036C63.0822 77.756 73.0692 69.63 77.7392 60.112C80.1562 55.185 80.4922 53.341 80.4922 45C80.4922 36.688 80.1522 34.807 77.7732 29.962C74.2792 22.842 67.0602 15.653 60.0382 12.3C52.4632 8.68298 40.8212 8.09298 33.1482 10.938Z"
          fill="inherit"
        />
      </svg>
    ),
    text: 'Facebook',
  },

  {
    link: process.env.REACT_APP_YOUTUBE,
    icon: (
      <svg
        viewBox="0 0 90 90"
        fill="inherit"
        className={classesSVG}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 13.5613C6.424 16.1923 6 16.8943 6 45.0003C6 73.4113 6.177 73.6913 25.823 76.4313C40.033 78.4133 49.967 78.4133 64.177 76.4313C83.823 73.6913 84 73.4113 84 45.0003C84 16.5893 83.823 16.3093 64.177 13.5693C50.869 11.7123 38.067 11.7103 25 13.5613ZM36 45.0003C36 52.1503 36.28 58.0003 36.622 58.0003C37.694 58.0003 59 45.6233 59 45.0003C59 44.3773 37.694 32.0003 36.622 32.0003C36.28 32.0003 36 37.8503 36 45.0003Z"
          fill="inherit"
        />
      </svg>
    ),
    text: 'Youtube',
  },
]

export default function SocialProfile({ classes }: { classes?: string }) {
  return (
    <article className={`flex ${classes}`}>
      {profiles.map((profile, index) => {
        return (
          <a
            key={index}
            href={profile.link}
            rel="noopener"
            target="_blank"
            className="outline-none group"
          >
            {profile.icon}
          </a>
        )
      })}
    </article>
  )
}
