import React from 'react'
import ComplianceChart, { ComplienceData } from 'organisms/complianceChart'

const chartData: ComplienceData = {
  th: ['Pressure (atm)', 'Balloon diameter (Ø mm)'],
  tableWidth: '',
  columnWidths: ['', ''],

  columnSpanStructure: [1, 14],
  td: [
    {
      text: [
        '',
        '1.50',
        '1.75',
        '2.00',
        '2.25',
        '2.50',
        '2.75',
        '2.87',
        '3.00',
        '3.25',
        '3.50',
        '3.75',
        '4.00',
        '4.50',
        '5.00',
      ],
      textColor: [
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
      ],
      bgColor: [
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
      ],
    },
    {
      text: [
        '8',
        '1.44',
        '1.70',
        '1.95',
        '2.20',
        '2.44',
        '2.68',
        '2.81',
        '2.91',
        '3.17',
        '3.41',
        '3.66',
        '3.89',
        '4.37',
        '4.85',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
      ],
    },
    {
      text: [
        '10',
        '1.46',
        '1.72',
        '1.97',
        '2.21',
        '2.46',
        '2.70',
        '2.83',
        '2.94',
        '3.19',
        '3.44',
        '3.69',
        '3.93',
        '4.41',
        '4.90',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
      ],
    },
    {
      text: [
        '12',
        '1.48',
        '1.73',
        '1.98',
        '2.23',
        '2.48',
        '2.73',
        '2.85',
        '2.97',
        '3.22',
        '3.47',
        '3.72',
        '3.96',
        '4.46',
        '4.95',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
      ],
    },
    {
      text: [
        '14 (NOM)*',
        '1.50',
        '1.75',
        '2.00',
        '2.25',
        '2.50',
        '2.75',
        '2.87',
        '3.00',
        '3.25',
        '3.50',
        '3.75',
        '4.00',
        '4.50',
        '5.00',
      ],
      textColor: [
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
      ],
      bgColor: [
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
      ],
    },
    {
      text: [
        '16',
        '1.52',
        '1.77',
        '2.02',
        '2.27',
        '2.52',
        '2.77',
        '2.90',
        '3.03',
        '3.28',
        '3.53',
        '3.78',
        '4.04',
        '4.54',
        '5.05',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
      ],
    },
    {
      text: [
        '18',
        '1.54',
        '1.78',
        '2.03',
        '2.29',
        '2.54',
        '2.80',
        '2.92',
        '3.06',
        '3.31',
        '3.56',
        '3.81',
        '4.07',
        '4.59',
        '5.10',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
      ],
    },
    {
      text: [
        '20 (RBP)',
        '1.56',
        '1.80',
        '2.05',
        '2.30',
        '2.56',
        '2.82',
        '2.94',
        '3.09',
        '3.33',
        '3.59',
        '3.84',
        '4.11',
        '4.63',
        '5.15',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-white',
        'text-white',
      ],
      bgColor: [
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-black',
        'bg-black',
      ],
    },
    {
      text: [
        '22 (RBP)**',
        '1.58',
        '1.82',
        '2.07',
        '2.32',
        '2.58',
        '2.84',
        '2.96',
        '3.12',
        '3.36',
        '3.62',
        '3.87',
        '4.15',
        '4.67',
        '5.21',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-white',
        'text-white',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-black',
        'bg-black',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
      ],
    },
    {
      text: [
        '24 (RBP)**',
        '1.60',
        '1.84',
        '2.09',
        '2.34',
        '2.60',
        '2.86',
        '2.99',
        '3.15',
        '3.39',
        '3.65',
        '3.90',
        '4.19',
        '4.71',
        '5.26',
      ],
      textColor: [
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
      ],
    },
    {
      text: [
        '26',
        '1.62',
        '1.85',
        '2.10',
        '2.36',
        '2.62',
        '2.89',
        '3.01',
        '3.18',
        '3.42',
        '3.68',
        '3.93',
        '4.22',
        '-',
        '-',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
      ],
    },
    {
      text: [
        '28',
        '1.64',
        '1.87',
        '2.12',
        '2.38',
        '2.64',
        '2.91',
        '3.03',
        '3.20',
        '3.45',
        '3.71',
        '-',
        '-',
        '-',
        '-',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
      ],
    },
    {
      text: [
        '30',
        '1.66',
        '1.89',
        '2.14',
        '2.39',
        '2.66',
        '2.93',
        '3.05',
        '3.23',
        '3.47',
        '3.74',
        '-',
        '-',
        '-',
        '-',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
      ],
    },
  ],
}

const Complience = () => {
  return (
    <ComplianceChart
      heading="Compliance chart"
      bgColor="bg-[#F0F4F5]"
      bgGradient="bg-[url(assets/bg-overlay/green-ellipse-bg.webp)]"
      complience={chartData}
    />
  )
}

export default Complience
