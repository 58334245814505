import React from 'react'
import CallIcon from 'assets/contact/call-icon.svg'
import EmailIcon from 'assets/contact/email-icon.svg'
import { useAdmin } from 'store/store'

const ContactDetails = () => {
  const { homeData } = useAdmin()

  return (
    <div className="lg:w-[40%] self-stretch lg:border-r-2 font-regular border-opacity-25 border-white bg-lightorange blade-bottom-padding-lg blade-top-padding-lg 2xl:blade-top-padding-lg md:blade-top-padding md:px-4  ">
      <div className=" px-3 md:-mt-2 2xl:mt-0 lg:sticky lg:top-20 flex-col flex gap-x-10 2xl:w-10/12 lg:max-w-md md:pl-5 max-w-4xl lg gap-y-6 :flex-row lg:flex-col   justify-around lg:justify-center w-11/12  lg:mx-auto">
        <div className=" flex gap-x-4 lg:mt-4 ">
          <span className="w-[3.5rem]">
            <img src={CallIcon} alt="call icon" aria-hidden />
          </span>
          <div className=" w-full ">
            <h4 className="font-medium ">Call us</h4>

            <div className="w-[60px] mt-2 border-b-1 border-white border-opacity-60" />

            <div className="lg:flex-col gap-x-20 sm:flex-row flex-col flex ">
              <div>
                <h6 className="font-medium text-opacity-60 text-black mt-5 md:text-base text-sm lg:text-lg">
                  Sales
                </h6>
                <a
                  href={`tel:${homeData.SECTION_11?.salesContact}`}
                  className="font-regular text-base  lg:text-xl outline-none focus-visible:underline underline-offset-4"
                >
                  {homeData.SECTION_11?.salesContact}
                </a>
              </div>

              <div>
                <h6 className="font-medium text-opacity-60 text-black mt-3 sm:mt-5 md:text-base text-sm lg:text-lg">
                  Service
                </h6>
                <a
                  href={`tel:${homeData.SECTION_11?.serviceContact}`}
                  className="font-regular text-base  lg:text-xl outline-none focus-visible:underline underline-offset-4"
                >
                  {homeData.SECTION_11?.serviceContact}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex gap-x-4 md:mt-0 lg:mt-12">
          <span className="w-12">
            <img src={EmailIcon} alt="email icon" />
          </span>

          <div>
            <h4 className="font-medium ">Send a query anytime</h4>

            <div className="w-[60px] mt-2 border-b-1 border-white border-opacity-60" />
            <a
              href={`mailto:${homeData.SECTION_11?.supportMail}`}
              className="font-regular  mt-3 md:mt-5 block text-base lg:text-xl outline-none focus-visible:underline underline-offset-4"
            >
              {homeData.SECTION_11?.supportMail}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactDetails
