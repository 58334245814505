import React from 'react'
import AuthWrapperHOC from 'layouts/authWrapperHOC'

const AdminPage = () => {
  return (
    <AuthWrapperHOC>
      <div />
    </AuthWrapperHOC>
  )
}

export default AdminPage
