import React, { useLayoutEffect } from 'react'
import { RenderMarkdown } from 'organisms'

const content = `
#### **A. PRIVACY POLICY**

##### 1. ACCEPTANCE OF TERMS    

1.  Innvolution Group (defined below) (hereinafter referred to “We/Us/Our”) are committed in protecting the personal information of Our website visitors, representatives of business partners, vendors, suppliers, visitors and employment candidates. This privacy policy governs the manner in which http://www.innvolutionimaging.com website (“Website”) collects, uses, maintains and discloses information collected from users (each, a “User”). This privacy policy applies to the Website and all products and services offered. You agree to and are bound by the terms and conditions set forth below and in any modified or additional terms that We may publish from time to time (collectively, the “Privacy  Policy”).
    
2.  “Product/Products” means and includes any goods / merchandise / products / services / offers / display items that are uploaded/showcased/displayed on the Website and the related description, information, procedure, processes, warranties, delivery schedule, etc.
    
3.  “Innvolution Group” means and includes Innvolution Healthcare Private Limited (“IHPL”), and its subsidiaries including Innovation Imaging Technologies Private Limited (“IITPL”), and all affiliates associated with it.

4.  If you do not agree to all of the terms and conditions contained in the Terms of Use, do not access or use this Website.
    

##### 2.  PERSONAL IDENTIFICATION INFORMATION
    

We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our Website, registers on the Website, place an order, and in connection with other activities, services, features or resources we make available on our Website. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Website anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Website related activities.
    

##### 3. NON-PERSONAL IDENTIFICATION INFORMATION
    

We may collect non-personal identification information about Users whenever they interact with our Website. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Website, such as the operating system and the Internet service providers' utilized and other similar information.
    

##### 4. WEB BROWSER COOKIES
    
1.  A cookie is an element of data that a website can send to your browser, which may then store it on your system. We use cookies in some of our pages to store visitors' preferences and record session information. The information that we collect is then used to ensure a more personalised service level for our users. You can adjust the settings on your browser so that you will be notified when you receive a cookie, or to disable the cookies associated with our Website. Please refer to your browser documentation to check if cookies have been enabled on your computer or to request not to receive cookies.
    
2.  Our Website may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies or to alert you when cookies are being sent. If they do so, note that some parts of the Website may not function properly.
    

##### 5.   HOW WE USE COLLECTED INFORMATION
    
We will ensure that the collection, use and disclosure of any personal data that we receive from you pursuant to your use of the Website will be kept in strict accordance with all applicable laws. We may collect and use Users personal information for the following purposes:
    
1.  To improve customer service - Information you provide helps us respond to your customer service requests and support needs more efficiently.
    
2.  To personalize user experience - We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Website.
    
3.  To improve our Website - We may use feedback you provide to improve our products and services.
    
4.  To process payments - We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.
    
5.  To send periodic emails - We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or User may contact us via our Website.
    
6.  To run a promotion, contest, survey or other Website feature - To send Users information they agreed to receive about topics we think will be of interest to them.
    

##### 6. HOW WE PROTECT YOUR INFORMATION
    

 1.   We implement reasonable security practices and procedures and has a comprehensive documented information security programme and information security policies that contain managerial, technical, operational and physical security control measures that are commensurate with respect to the information being collected and the nature of our business. We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Website.
    
 2.   Sensitive and private data exchange between the Website and its Users happens over an SSL secured communication channel and is encrypted and protected with digital signatures. Our Website is also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users.
    

##### 7.   SHARING YOUR PERSONAL INFORMATION
    

 1.   We may use third-party service providers to help us operate our business and the Website or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us your permission. If you access those links, you will leave the Website. We do not control those sites or their privacy practices, which may differ from ours. We do not endorse or make any representations about third-party sites.
    
 2.   We may share your information within the Innvolution Group including but not limited to our subsidiaries and affiliates to help us operate, provide, improve, understand, customize, support, and market our Products. This is ensure the following for our Products, i.e., to provide technical infrastructure, delivery and other systems; market our Products; conduct surveys and research for us; protect the safety, security, and integrity of users and others; and assist with customer service.
    
 3.   We are not responsible for examining or evaluating, and does not warrant the offerings of, any businesses or individuals or the content of their websites. We do not assume any responsibility or liability for the actions, products, services, and content of any other third-parties. You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Website. Your linking to any other off-site websites is at your own risk.
    
 4.   Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our Website from those we know are under 18, and no part of our website is structured to attract anyone under 18.
    

##### 8.   CHANGES TO THIS PRIVACY POLICY

www.innvolutionimaging.com has the discretion to update this privacy policy at any time. When we do, we will send you an email. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
    

##### 9. CONTACT US
    
If you have any questions or comments about our Terms of Service as outlined above, you can contact us at:
    
INNOVATION IMAGING TECHNOLOGIES PRIVATE LIMITED

Registered Address: No. 121F, 1st Phase, Bommasandra Industrial Area, Hulimangala Road, opposite Old Mahindra Reva Factory, next to VJ Imaging Technologies Pvt. Ltd, Bengaluru, Karnataka 560099

Email: info@innvolution.com

Phone: +91 6364 882 807


##### 10. CONFIDENTIALITY  STATEMENT
    
At the Innvolution Group, we place a major emphasis on maintaining the confidentiality of client information and Information Security Management. The following are key features of our Confidentiality Agreement, applicable to all our clients.
    

a)  We do not disclose any information pertaining to our clients to any third parties.

b)  We do not use our clients identity or clients brand for our marketing purposes, without their written consent.

c)  We never provide our client's name or client's information to other prospective clients for marketing purposes.

d)  We do not sell or disclose our client list or client Information to third parties.


##### 11. CONFIDENTIALITY AGREEMENT
    
It is understood and agreed that Our client may provide certain information that is and must be kept confidential. To ensure the protection of such information, and to preserve any confidentiality necessary under patent and/or trade secret laws, it is agreed that:
    

a)  The Confidential Information to be disclosed can be described as and includes Invention description(s), technical and business information relating to proprietary ideas and inventions, ideas, patentable ideas, trade secrets, drawings and/or illustrations, patent searches, existing and/or contemplated products and services, research and development, production, costs, profit and margin information, finances and financial projections, customers, clients, marketing, and current or future business plans and models, regardless of whether such information is designated as "Confidential Information" at the time of its disclosure.

b)  The Innvolution Group shall limit disclosure of Confidential Information within its own organization to its directors, officers, partners, members, employees and/or independent contractors (collectively referred to as "affiliates") having a need to know. We and our affiliates will not disclose the confidential information obtained from the discloser unless required to do so by law.

c)  This Agreement imposes no obligation upon Us with respect to any Confidential Information (a) that was in Our possession before receipt from Our client; (b) is or becomes a matter of public knowledge through no fault of Ours; (c) is rightfully received by Us from a third party not owing a duty of confidentiality to Our client; (d) is disclosed without a duty of confidentiality to a third party by, or with Our authorization.; (e) is public knowledge or the information is available in public domain; or (f) is independently derived by Us.

d)  This Agreement states the entire agreement between Us and Our client concerning the disclosure of Confidential Information. Any addition or modification to this Agreement must be made in writing and signed by the parties.

e)  If any of the provisions of this Agreement are found to be unenforceable, the remainder shall be enforced as fully as possible and the unenforceable provision(s) shall be deemed modified to the limited extent required to permit enforcement of the Agreement as a whole.

f)  You are solely responsible any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liability.


##### 12. DISCLAIMER  POLICY
    

THE WEBSITE IS AN ONLINE INFORMATION SERVICE PROVIDED BY INNOVATION IMAGING TECHNOLOGIES PRIVATE LIMITED ("WWW.INNVOLUTIONIMAGING.COM"), SUBJECT TO YOUR COMPLIANCE WITH THE TERMS AND CONDITIONS SET FORTH BELOW. PLEASE READ THIS DOCUMENT CAREFULLY BEFORE ACCESSING OR USING THE WEBSITE. BY ACCESSING OR USING THE WEBSITE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH BELOW. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS AND CONDITIONS, YOU MAY NOT ACCESS OR USE THE WEBSITE. WWW.INNVOLUTIONIMAGING.COM MAY MODIFY THIS AGREEMENT AT ANY TIME, AND SUCH MODIFICATIONS SHALL BE EFFECTIVE IMMEDIATELY UPON POSTING OF THE MODIFIED AGREEMENT ON THE WEBSITE. YOU AGREE TO REVIEW THE AGREEMENT PERIODICALLY TO BE AWARE OF SUCH MODIFICATIONS AND YOUR CONTINUED ACCESS OR USE OF THE WEBSITE SHALL BE DEEMED YOUR CONCLUSIVE ACCEPTANCE OF THE MODIFIED AGREEMENT.
    

##### 13. COPYRIGHT, LICENSES AND IDEA SUBMISSIONS
    
 1.  The entire contents of the Website are protected by international copyright and trademark laws. The owner of the copyrights and trademarks are www.innvolutionimaging.com, its affiliates or other third-party licensors.
    
 2.  You may not modify, copy, reproduce, republish, upload, post, transmit, or distribute, in any manner, the material on the Website, including text, graphics, code and/or software.
    
 3.  You may print and download portions of material from the different areas of the Website solely for your own non-commercial use provided that you agree not to change or delete any copyright or proprietary notices from the materials. You agree to grant to www.innvolutionimaging.com a non-exclusive, royalty-free, worldwide, perpetual license, with the right to sub-license, to reproduce, distribute, transmit, create derivative works of, publicly display and publicly perform any materials and other information (including, without limitation, ideas contained therein for new or improved products and services) you submit to any public areas of the Website (such as bulletin boards, forums and newsgroups) or by e-mail to www.innvolutionimaging.com by all means and in any media now known or hereafter developed. You also grant to www.innvolutionimaging.com the right to use your name in connection with the submitted materials and other information as well as in connection with all advertising, marketing and promotional material related thereto. You agree that you shall have no recourse against www.innvolutionimaging.com for any alleged or actual infringement or misappropriation of any proprietary right in your communications to www.innvolutionimaging.com.
    

##### 14.  TRADEMARKS
    
Publications, products, content or services referenced herein or on the Website are the exclusive trademarks or service marks of www.innvolutionimaging.com. Other product and company names mentioned in the Website may be the trademarks of their respective owners.
    

##### 15.  USE OF THE WEBSITE
    

1.  You understand that, except for information, products or services clearly identified as being supplied by www.innvolutionimaging.com, www.innvolutionimaging.com does not operate, control or endorse any information, products or services on the Internet in any way. Except for www.innvolutionimaging.com - identified information, products or services, all information, products and services offered through the Website or on the Internet generally are offered by third parties that are not affiliated with www.innvolutionimaging.com. You also understand that www.innvolutionimaging.com cannot and does not guarantee or warrant that files available for downloading through the Website will be free of infection or viruses, worms, Trojan horses or other code that manifest contaminating or destructive properties. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and for maintaining a means external to the Website for the reconstruction of any lost data.
    
2.  You assume total responsibility and risk for your use of the website and the internet. www.innvolutionimaging.com provides the website and related information "as is" and does not make any express or implied warranties, representations or endorsements whatsoever (including without limitation warranties of title or non-infringement, or the implied warranties of merchantability or fitness for a particular purpose) with regard to the service, any merchandise information or service provided through the service or on the internet generally, and www.innvolutionimaging.com shall not be liable for any cost or damage arising either directly or indirectly from any such transaction. It is solely your responsibility to evaluate the accuracy, completeness and usefulness of all opinions, advice, services, merchandise and other information provided through the service or on the internet generally. www.innvolutionimaging.com does not warrant that the service will be uninterrupted or error-free or that defects in the service will be corrected. You understand further that the pure nature of the internet contains unedited materials some of which are sexually explicit or may be offensive to you. Your access to such materials is at your risk. www.innvolutionimaging.com has no control over and accepts no responsibility whatsoever for such materials.
    

##### 16.  LIMITATION OF LIABILITY
    
1.  In no event will www.innvolutionimaging.com be liable for (i) any incidental, consequential, or indirect damages (including, but not limited to, damages for loss of profits, business interruption, loss of programs or information, and the like) arising out of the use of or inability to use the service, or any information, or transactions provided on the service, or downloaded from the service, or any delay of such information or service. Even if www.innvolutionimaging.com or its authorized representatives have been advised of the possibility of such damages, or (ii) any claim attributable to errors, omissions, or other inaccuracies in the service and/or materials or information downloaded through the service. Because some states do not allow the exclusion or limitation of liability for consequential or incidental damages, the above limitation may not apply to you. In such states, www.innvolutionimaging.com liability is limited to the greatest extent permitted by law.
    
2.  www.innvolutionimaging.com makes no representations whatsoever about any other web website which you may access through this one or which may link to this website. When you access a non- www.innvolutionimaging.com website, please understand that it is independent from www.innvolutionimaging.com, and that www.innvolutionimaging.com has no control over the content on that web website. In addition, a link to a www.innvolutionimaging.com website does not mean that www.innvolutionimaging.com endorses or accepts any responsibility for the content, or the use, of such Website.
    

##### 17.  INDEMNIFICATION
    
You agree to indemnify, defend and hold harmless www.innvolutionimaging.com, its officers, directors, employees, agents, licensors, suppliers and any third party information providers to the Service from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation of this Policy (including negligent or wrongful conduct) by you or any other person accessing the Service.
    

##### 18.  THIRD PARTY RIGHTS
    
1.  The provisions above (Use of the Site), and (Indemnification) are for the benefit of www.innvolutionimaging.com and its officers, directors, employees, agents, licensors, suppliers, and any third-party information providers to the Service. Each of these individuals or entities shall have the right to assert and enforce those provisions directly against you on its own behalf.
    
2.  In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us. However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions. For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.
    
3.  Certain providers may be located in or have facilities that are located in a different jurisdiction than either you or us. If you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.
    
4.  Once you leave our Website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.
    

 ##### 19.  MISCELLANEOUS
    
1.  This Privacy Policy shall all be governed and construed in accordance with the laws of India applicable to agreements made and to be performed in India. You agree that any legal action or proceeding between www.innvolutionimaging.com and you for any purpose concerning this Agreement or the parties' obligations hereunder shall be brought exclusively in a federal or state court of competent jurisdiction sitting in India. Any cause of action or claim you may have with respect to the Service must be commenced within one (1) year after the claim or cause of action arises or such claim or cause of action is barred. www.innvolutionimaging.com failure to insist upon or enforce strict performance of any provision of this Agreement shall not be construed as a waiver of any provision or right. Neither the course of conduct between the parties nor trade practice shall act to modify any provision of this Agreement. www.innvolutionimaging.com may assign its rights and duties under this Agreement to any party at any time without notice to you.
    
2.  Any rights not expressly granted herein are reserved.
    



##### **B. COOKIE POLICY**
    
 The statements included herein disclose the cookie practices (“Cookie Policy”) of the Website to help visitors to understand how and why cookies are saved and used to increment the visitors experience of the Website.


##### **1. What is a cookie:**

1.  Cookie is small web file that a web browser creates and stores in the device used by a visitor either temporarily or permanently in order to make a web page more responsive to the requirements of such visitor. Our Privacy Policy describes how the Website collects and uses information from visitors of our Website and one way we collect such information is through the use of cookies. To help making our Website more responsive to the needs of our visitors, we use cookies to assign each visitor a unique, random identity that resides on the visitor’s device. Such cookies do not personally identify the visitor as an individual, instead, identify the device that a visitor uses to access our Website. Unless you voluntarily identify yourself (through registration, for example), we will have no knowledge of who you are, even if we assign a cookie to your device.
    
2.  Cookies perform lots of different jobs to enhance your experience, i.e. allow you to navigate between pages efficiently by remembering your preferences and hence, disabling cookies may prevent you from using certain parts of the Website and Mobile Site. We use cookies to help us tailor our Website in accordance with your needs, and to deliver a better service that is keyed to the interests of our visitors. We use cookies to determine what features our visitors prefer and which ones are less preferred. By knowing which pages our visitors visit and how often, we aim to improvise and build better content.
    

##### **2.  What are the cookies used by the Website:**

We use the following categories of cookies:

Essential cookies: We create and store cookies the moment you log in to our Website. These cookies are used to manage your login sessions. These cookies are typically removed or cleared when you log out in order to ensure that you can only access such features and areas when you are logged in.
    

##### **3.  Do these cookies collect personal data/identify me?**
    

Most types of cookies track consumers via their device id or internet protocol (“IP”) address therefore they may collect personal data. Based on the type of cookies used by the relevant third-party, the information these cookies collect may include personal data but they may not be able to directly identify you as an individual or have any of your personal information such as name, e-mail id or mobile number.
    

##### **4.  Can you control cookies collected on this Website:**
    

1.  If you do not want cookies to be created and stored on your device, there are some options available to help you anonymously visit websites, including the possibility to set your web browser to block all cookies or to initiate an anonymous browsing session.
    
2.  You can also delete cookies after having visited our Website. Moreover, if you browse our Website using your web browser’s anonymous visitor setting (called “Incognito” in Chrome, “InPrivate” in Internet Explorer etc.), cookies cannot be set on your device.
    
3.  Further, all major web browsers offer security provisions to block all or some specific or certain third-party cookies. You may also activate such settings on your web browser that may allow you to refuse cookies. Unless you set your web browser to refuse cookies, our system will create and store cookies on your device when you visit or login to our Website.
    
4.  We strongly recommend that you accept cookies, because cookies enable you to take advantage of some of our Website’s features. If you do not want to avail the benefit of cookies, there is a simple process to manually delete them. The “help” option of the toolbar in most web browsers will provide information on how to prevent your web browser from accepting new cookies, how to tell your web browser to inform you when you receive a new cookie or how to disable cookies altogether.
    

##### **5.  Conditions of use:**
    

If you decide to visit our Website, your visit and any possible dispute over privacy is subject to the Cookie Policy, Privacy Policy and Terms and Conditions.
    

##### **6.  Future Amendments:**
    

The internet is an evolving medium. We may change our Cookie Policy time to time and if we do, we will post the changes before such policies take effect. Your continued use of the Website post such notification on amendments will constitute your acceptance of such amendments. We encourage you to review our Cookie Policy whenever you visit the Site.
    

##### **7.  More Information**
    

If you have any queries regarding this Cookie Policy, please contact us at:

###### INNOVATION IMAGING TECHNOLOGIES PRIVATE LIMITED

**Registered Address:** No. 121F, 1st Phase, Bommasandra Industrial Area, Hulimangala Road, opposite Old Mahindra Reva Factory, next to VJ Imaging Technologies Pvt. Ltd, Bengaluru, Karnataka 560099

**Email**: info@innvolution.com

**Phone**: +91 6364 882 807
`

export default function PrivacyPolicy() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="pt-[4.5rem] md:pt-24 mb-4 lg:pt-32 lg:pb-10">
        {/* <h3 className="font-medium text-center px-5 sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg leading-relaxed ">
          Privacy policy
        </h3> */}
      </div>
      <div className="markdown-wrapper md:max-w-xl lg:max-w-2xl xl:max-w-3xl 2xl:max-w-5xl sm:px-5 px-3 mx-auto blade-bottom-padding-lg ">
        <RenderMarkdown content={content} />
      </div>
    </>
  )
}
