import React, { useLayoutEffect } from 'react'
import { RenderMarkdown } from 'organisms'

const content = `
##### A. TERMS AND CONDITIONS


##### 1. ACCEPTANCE OF TERMS
    

1.  Welcome to [www.innvolution.com](http://www.innvolution.com) (“Website/Mobile Site”). This website is owned and operated by Innvolution Group (defined below) (hereinafter referred to as “We/Us/Our”).
    
2.  “Innvolution Group” means and includes Innvolution Healthcare Private Limited (“IHPL”), and its subsidiaries including Innovation Imaging Technologies Private Limited (“IITPL”), and all affiliates associated with it.
    
3.  These terms and conditions (“Terms of Use”) are an agreement between Us and you (“User/you/your"). This Agreement sets forth the general terms and conditions of your use of the Website or the Mobile Website and any of its information, products or services (collectively, "Services"). Your access to the Website/ Mobile Website and use of the Services is conditional on your acceptance of and compliance with these Terms of Use as well as the Privacy Policy and Cookie Policy. These Terms of Use shall apply to all visitors, Users and others who access the Website/ Mobile Site or use the Services. By accessing or using the Services you agree to be bound by these Terms of Use. These Terms of Use shall be read along with the terms and conditions under our Privacy Policy (please refer to the Privacy Policy section below for more information).

4.  We reserve the right to change these Terms of Use from time to time without prior notice. You acknowledge and agree that it is your responsibility to review these Terms of Use periodically to familiarize yourself with any modifications. Your continued use of this Website/ Mobile Site after such modifications will constitute acknowledgement and agreement of the modified terms and conditions.

5.  Use of Website/ Mobile Site is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you may use the Website/ Mobile Site only with the involvement of a parent or guardian
    

##### 2. RESPONSIBLE USE AND CONDUCT
    

1.  In order to access our Services, you may be required to provide certain information about yourself (such as identification, email, phone number, contact details, etc.) as part of the registration process, or as part of your ability to use the Services. You agree that any information you provide will always be accurate, correct, and up to date.
    
2.  You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Services. Accordingly, you are responsible for all activities that occur under your account/s.
    
3.  Accessing (or attempting to access) any of our Services by any means other than through the means we provide, is strictly prohibited. You specifically agree not to access (or attempt to access) any of our Services through any automated, unethical or unconventional means.
    
4.  Engaging in any activity that disrupts or interferes with our Services, including the servers and/or networks to which our Services are located or connected, is strictly prohibited.
    
5.  Attempting to copy, duplicate, reproduce, sell, trade, or resell our resources is strictly prohibited.
    
6.  You are solely responsible for any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liability.
    
7.  We may provide various open communication tools on our website, such as blog comments, blog posts, public chat, forums, message boards, newsgroups, product ratings and reviews, various social media services, etc. You understand that generally we do not pre-screen or monitor the content posted by users of these various communication tools, which means that if you choose to use these tools to submit any type of content to our website, then it is your personal responsibility to use these tools in a responsible and ethical manner. By posting information or otherwise using any open communication tools as mentioned, you agree that you will not upload, post, share or otherwise distribute any content:
    
       a) that is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent, deceptive, invasive, racist, or contains any type of suggestive, inappropriate, or explicit language;
    
       b) that infringes on any trademark, patent, trade secret, copyright, or other proprietary rights of any party;
    
       c) that contains any type of unauthorized or unsolicited advertising;
    
       d) that impersonates any person or entity, including any www.innvolutionimaging.com employees or representatives;
    
       e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;
    
       f) to submit false or misleading information;
    
       g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;
    
       h) to collect or track the personal information of others;
    
       i) to spam, phish, pharm, pretext, spider, crawl, or scrape;
    
       j) for any obscene or immoral purpose; or
    
       k) to interfere with or circumvent the security features of the Service or the Website/ Mobile Site or any related website, other websites, or the internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.

8.  We have the right at our sole discretion to remove any content that, we feel in our judgment does not comply with this Terms of Use, along with any content that we feel is otherwise offensive, harmful, objectionable, inaccurate, or violates any third party copyrights or trademarks. We are not responsible for any delay or failure in removing such content. If you post content that we choose to remove, you hereby consent to such removal, and consent to waive any claim against us.
    
9.  We do not assume any liability for any content posted by you or any other third party users of our Website / Mobile Site. However, any content posted by you using any open communication tools on our website, provided that it doesn't violate or infringe on any third party copyrights or trademarks, becomes the property of www.innvolutionimaging.com and as such, gives us a perpetual, irrevocable, worldwide, royalty-free, exclusive license to reproduce, modify, adapt, translate, publish, publicly display and/or distribute as we see fit. This only refers and applies to content posted via open communication tools as described and does not refer to information that is provided as part of the registration process, necessary in order to use our Services. All information provided as part of our registration process is covered by our Privacy Policy
    
10.  You agree to indemnify and hold harmless www.innvolutionimaging.com a digital property of INNOVATION IMAGING TECHNOLOGIES PRIVATE LIMITED and its parent company and affiliates, and their directors, officers, managers, employees, donors, agents, and licensors, from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation of these Terms of Use or the failure to fulfil any obligations relating to your account incurred by you or any other person using your account. We reserve the right to take over the exclusive defence of any claim for which we are entitled to indemnification under these Terms of Use. In such event, you shall provide us with such cooperation as is reasonably requested by us.
    
11.  Although this Website / Mobile Site may link to other websites, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein. We are not responsible for examining or evaluating, and does not warrant the offerings of, any businesses or individuals or the content of their websites. We do not assume any responsibility or liability for the actions, products, services, and content of any other third-parties. You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Website / Mobile Site. Your linking to any other off-site websites is at your own risk.
    

##### 3. PRIVACY
    

Your privacy is very important to us, which is why we've created a separate Privacy Policy in order to explain in detail how we collect, manage, process, secure, and store your private information. Our privacy policy is included under the scope of these Terms of Use. To read our Privacy Policy in its entirety, please see the below.
    

##### 4. DATA AND ACCOUNT SECURITY

1.  In consideration of your use of the Website/ Mobile Site, you agree to: 

      a) maintain and promptly update any information you provide to Us, to keep it accurate, current and complete; 
         
      b) maintain confidentiality and security of your password and identification; 
         
      c) notify Us immediately of any unauthorized use of your account or other breach of security; 
         
      d) accept all responsibility for any and all activities that occur under your account; 
         
      e) accept all risks of unauthorized access to the data and any other information you provide to Us.


    
2.  The User shall be responsible for all usage of the User’s account and password, whether or not authorized by the User. Although We will not be liable for your losses caused by any unauthorized use of your account, you may be liable for the losses of Innvolution Group or such other parties as the case may be, due to any unauthorized use of your account.
    
3.  Using the Website/ Mobile Site may include downloading software to your computer, phone, tablet, or other device. You agree that we may automatically update that software, and these Terms will apply to any such updates.
    

##### 5. USERS’ CODE OF CONDUCT
    

1.  This Website/ Mobile Site are intended for users of the Website/ Mobile Site only. You may not use this Website/ Mobile Site for any other purpose, which is not related to your business with the Services. Any use of this Website/ Mobile Site or any of its functionality for a purpose not permitted by these Terms of Use can be grounds for immediate revocation of any usernames, pass codes or other permissions that may have been granted by Us for use of this Website/ Mobile Site.
    
2.  You are specifically prohibited from: (i) downloading, copying, or retransmitting any or all of the Website/ Mobile Site content without, or in violation of, a written license or agreement with Innvolution Group or a specific option to do so as provided by Innvolution Group; (ii) using any data mining or similar data gathering or extraction methods; (iii) manipulating or otherwise displaying the Website/ Mobile Site content by using, framing or similar navigational technology; and (iv) using Website/ Mobile Site other than for its intended purpose. Such unauthorized use may also violate applicable laws including without limitation copyright and trade mark laws, the laws of privacy and publicity, and applicable communications regulations and statutes. You represent and warrant that you will comply with all applicable regulations, including, without limitation, those relating to the internet, data, e-mail, privacy and the transmission of technical data exported from India or the country in which you reside.
    

##### 6. LIMITATION OF LIABILITY
    

You expressly understand and agree that any claim against us shall be limited to the amount you paid, if any, for use of products and/or services. www.innvolutionimaging.com will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you as a result of using our Services, or as a result of any changes, data loss or corruption, cancellation, loss of access, or downtime to the full extent that applicable limitation of liability laws apply.
    

##### 7. COPYRIGHTS / TRADEMARKS
    
1.  All content and materials available on www.innvolutionimaging.com, including but not limited to text, graphics, website name, code, images and logos are the intellectual property of the Innvolution Group and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission of any content on this Website/ Mobile Site is strictly prohibited unless specifically authorized by Innvolution Group.
    
2.  This Website/ Mobile Site is owned and operated by us. All the content published, featured or displayed on the Website/ Mobile Site, including, but not limited to, text, graphics, data, images (photographic and moving), illustrations, software and selection and arrangement thereof (“Content”), is owned by us. Any Content will be published, featured or displayed on the Website/ Mobile Site with the prior agreement with Innvolution Group.
    
3.  You may not use meta-tags or any other "hidden text" utilizing content or any other name, trade mark or product name of Innvolution Group without our prior written permission.
    
4.  In addition, the look and feel of the Website/ Mobile Site, including all page headers, custom graphics, button icons and scripts, trade mark and/or Our trade dress may not be copied, imitated or used, in whole or in part, without Our prior written permission. All other trademarks, registered trademarks, product names and company names or logos mentioned in the Website are the property of their respective owners. Reference to any products, services, processes or other information, by trade name, trade mark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by Us.
    
5.  This Terms of Use does not transfer to you any intellectual property owned by Us or third-parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with Us. All trademarks, graphics and logos used in connection with our Website/ Mobile Site or Services, are trademarks or registered trademarks of Innvolution Group or the Our licensors. Other trademarks, graphics and logos used in connection with the Website/ Mobile Site or Services may be the trademarks of other third-parties. Your use of the Website/ Mobile Site and Services grants you no right or license to reproduce or otherwise use any of Our or third-party trademarks.
    

##### 8. TERMINATION OF USE
    

You agree that we may, at our sole discretion, suspend or terminate your access to all or part of our website and Resources with or without notice and for any reason, including, without limitation, breach of this Terms of Use. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities. Upon suspension or termination, your right to use the Services provided by us will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information.
    

##### 9. GOVERNING LAW
    

1.  The Website/ Mobile Site is controlled by Us from our office located in the state of Karnataka, India. It can be accessed by most countries around the world. As each country has laws that may differ from those of India, by accessing our Website/ Mobile Site, you agree that the statutes and laws of India without regard to the conflict of laws and the United Nations Convention on the International Sales of Goods, will apply to all matters relating to the use of this website and the purchase of any products or services through this Website/ Mobile Site.
    
2.  Any issues arising out of use of Website/ Mobile Site and any conflict with regard to these Terms of Use and Privacy Policy shall be governed and construed in accordance with the laws of India and subject to exclusive jurisdiction of courts in Karnataka, India. You hereby agree to personal jurisdiction by such courts and waive any jurisdictional, venue, or inconvenient forum objections to such courts. Our failure to enforce any right or provision of these Terms of Use will not be considered a waiver of those rights.
    

##### 10. CANCELLATION AND REFUND
    

Cancellation of an order is not possible once the payment has been made. No refunds will be given except in the event of cancellation or non-performance of service by us.
    

##### 11. GUARANTEE
    

Unless otherwise expressed, www.innvolutionimaging.com expressly disclaims all warranties and conditions of any kind, whether express or implied, including, but not limited to the implied warranties and conditions of merchantability, fitness for a particular purpose and non-infringement.
    

##### 12. DISCLAIMERS
    

1.  The Innvolution Group does not make any representation or warranty about the Website/ Mobile Site or the services and products, except as expressly stated in the Terms of Use. Nothing on the Website/ Mobile Site is to be deemed as authoritative advice (whether legal, medical, financial, etc.) of any kind.
    
2.  You must bear the risks associated with the use of the Website/ Mobile Site. We will not be responsible for any errors or omissions or for the results obtained from the use of such information or for any technical problems you may experience with the Website/ Mobile Site.
    
3.  This disclaimer constitutes an essential part of these Terms of Use. To the fullest extent permitted under applicable law, we shall not be liable for any indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or in connection with the Website/ Mobile Site, its services or these Terms of Use.
    

##### 13. PRICE
    

1.  The prices of the products will be as quoted on our Website/ Mobile Site from time to time. We take all reasonable care to ensure that the prices of the products are correct at the time of publication.
    
2.  Prices for our products may change from time to time, but changes will not affect any order which we have confirmed with a dispatch confirmation.
    
3.  The price of a product is inclusive of indirect taxes such as but not limited to Central Goods and Services Act, 2017, State Goods and Services Act, 2017 and Integrated Goods and Services Act, 2017 (Indirect Taxes).
    
4.  The price of a product does not include any applicable delivery charges. Our delivery charges are as quoted here.
    
5.  Our site contains several products. It is always possible that, despite our reasonable efforts, some of the products on our site may be incorrectly priced. If we discover an error in the price of the products, we will inform you of this error and we will give you the option of continuing to purchase the product at the correct price or cancelling your order. We will not process your order until we have your instructions. If we are unable to contact you using the contact details you provided during the order process, we will treat the order as cancelled and notify you in writing. Please note that if the pricing error is obvious and unmistakeable and could have reasonably been recognised by you as a mispricing, we do not have to provide the products to you at the incorrect (lower) price.
    

##### 14. ENTIRE TERMS
    

If any part of these Terms of Use is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed to be superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the Terms shall continue in effect. Unless otherwise specified herein, these Terms and the Website policies constitute the entire agreement between you and us with respect to our sites/services and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and us with respect to the Website. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches.
    

##### 15. FORCE MAJEURE
    

1.  We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under a contract that is caused by an Event Outside Our Control. An Event Outside Our Control is defined below.
    
2.  An Event Outside Our Control means any act or event beyond our reasonable control, including without limitation strikes, lock-outs or other industrial action by third parties, civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war, fire, explosion, storm, flood, earthquake, subsidence, epidemic, pandemic, national or local lockdown, or other natural disaster, or failure of public or private telecommunications networks, or impossibility of the use of shipping, aircraft, motor transport or other means of public or private transport.
    
3.  If an Event Outside Our Control takes place that affects the performance of our obligations under a contract:
    
4.  We will contact you as soon as reasonably possible to notify you and our obligations under the contract will be suspended and the time for performance of our obligations will be extended for the duration of the Event Outside Our Control. Where the Event Outside Our Control affects our delivery of products to you, we will arrange a new delivery date with you after the Event Outside Our Control is over.
    

##### 16. CONTACT INFORMATION
    
If you have any questions or comments about our Terms of Service as outlined above, you can contact us at:
    

INNOVATION IMAGING TECHNOLOGIES PRIVATE LIMITED

**Registered Address**: No. 121 F, 1st Phase, Bommasandra Industrial Area, Hulimangala Road, opposite Old Mahindra Reva Factory, next to VJ Imaging Technologies Pvt. Ltd, Bengaluru, Karnataka 560099

**Email**: info@innvolution.com

**Phone**: +91 6364 882 807
`

export default function PrivacyPolicy() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="pt-[4.5rem] md:pt-24 mb-4 lg:pt-32 lg:pb-10">
        {/* <h3 className="font-medium text-center px-5 sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg leading-relaxed ">
          Terms and conditions
        </h3> */}
      </div>
      <div className="markdown-wrapper md:max-w-xl lg:max-w-2xl xl:max-w-3xl 2xl:max-w-5xl sm:px-5 px-3 mx-auto blade-bottom-padding-lg ">
        <RenderMarkdown content={content} />
      </div>
    </>
  )
}
