import React from 'react'
import useScrollToTop from './useScrollToTop'
import useGsapOpacity from './useGsapOpacity'
import useGsapOpacityTransAppear from './useGsapOpacityTransAppear'
import useGsapStagger from './useGsapStagger'
import useGsapScale from './useGsapScale'

const useGSAP = (pageClassName: string) => {
  useScrollToTop()
  useGsapOpacity(pageClassName + ' .gsap-opacity')
  useGsapOpacityTransAppear(pageClassName + ' .gsap-opacity-trans-appear')
  useGsapStagger(
    pageClassName + ' .gsap-stagger',
    pageClassName + ' .gsap-stagger-parent',
  )
  useGsapScale(pageClassName + ' .gsap-scale')
}

export default useGSAP

// GSAP animation classes :-
// --------------------------------------
// gsap-opacity
// gsap-opacity-trans-appear
// gsap-stagger
// gsap-stagger-parent
// gsap-scale

// CSS animation classes for banners :-
// ---------------------------------------
// text-appear-anim
// text-appear-anim-delayed
