import React from 'react'
// import benefitDesk from 'assets/scoreflexNC/benefits/benefitsDesk.svg'
// import benefitMob from 'assets/scoreflexNC/benefits/benefitsMob.svg'
import Benefits from 'organisms/benefits'

const BenefitsNC = () => {
  return (
    <Benefits
      bgGrad="linear-gradient(0deg, #F0F4F5 0%, #F0F4F5 100%),  #FFF"
      title="Excellent pushability and deliverability"
    />
  )
}

export default BenefitsNC
