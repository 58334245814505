import { MouseEventHandler } from 'react'

export default function PopOption({
  callback,
  text,
}: {
  text: string
  callback: MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <button
      type="button"
      onClick={callback}
      className=" py-1 flex flex-nowrap font-light items-center gap-1 group stroke-violet fill-violet outline-none focus:outline-none"
    >
      <span className="group-hover:text-orange group-hover:underline underline-offset-4 text-left text-sm lg:text-[0.9rem] group-focus-visible:text-orange group-focus-visible:underline text-opacity-60 font-regular  transition-colors duration-150 ease-in-out text-black  group-focus:text-orange group-focus:underline block">
        {text}
      </span>
    </button>
  )
}
