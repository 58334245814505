import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import { A11y, Navigation, Pagination } from 'swiper'
import { carousels } from './yearCarouselMobile'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

let lastScrollTop = 0

export const YearsCarousal = () => {
  const [activeSlide, setActiveSlide] = useState(0)
  const swiperRef = useRef<any>(null)
  const [instance, setInstance] = useState<any>(null)
  const [scrollController, setScrollController] = useState<any>(null)
  const [refreshGsap, setRefreshGsap] = useState(false)
  const [scrollingBottom, setScrollingBottom] = useState(true)
  const yearCarouselRef = useRef<any>(null)

  setTimeout(() => {
    setRefreshGsap(true)
  }, 100)

  useEffect(() => {
    document.addEventListener('scroll', scrollSideGetter)

    return () => {
      document.removeEventListener('scroll', scrollSideGetter)
    }
  }, [])

  function scrollSideGetter(e: any) {
    let currentScroll = window.pageYOffset || document.documentElement.scrollTop

    if (currentScroll > lastScrollTop) {
      // Scrolling down
      setScrollingBottom(true)
    } else {
      // Scrolling up
      setScrollingBottom(false)
    }

    lastScrollTop = currentScroll <= 0 ? 0 : currentScroll
  }

  useEffect(() => {
    const ctx = gsap.context(() => {
      const anim = gsap.from(yearCarouselRef.current, {})
      const controller = ScrollTrigger.create({
        animation: anim,
        trigger: yearCarouselRef.current,
        start: 'top top',
        end: 'bottom -500%',
        scrub: true,
        pin: true,
        onUpdate: (e: any) => {
          const progress: number = +(e.progress * 100).toFixed(2) ?? 0
          // console.log(progress)
          progressHandler(progress)
        },
      })
      setScrollController(controller)
    })

    return () => {
      ctx.revert()
    }
  }, [refreshGsap])

  useEffect(() => {
    setInstance(() => {
      if (swiperRef?.current && swiperRef.current?.swiper) {
        return swiperRef.current.swiper
      }
      return null
    })
  }, [])

  const setPosition = (update: number) => {
    scrollController?.scroll(
      scrollController.start +
        (update / carousels.length) *
          (scrollController.end - scrollController.start),
    )
  }

  const slidePosHandler = useCallback(
    (update: number) => {
      if (window.location.pathname === '/who-we-are') {
        instance?.slideTo(update)
        setActiveSlide(update)
      }
    },
    [instance],
  )

  const scrollPosHandler = (type: string) => {
    const onePartOfWholeProgress = +(100 / carousels.length).toFixed(2)
    switch (type) {
      case '-':
        scrollController?.scroll(
          scrollController.start +
            (scrollController.progress - onePartOfWholeProgress / 100) *
              (scrollController.end - scrollController.start),
        )
        break

      case '+':
        scrollController?.scroll(
          scrollController.start +
            (scrollController.progress + onePartOfWholeProgress / 100) *
              (scrollController.end - scrollController.start),
        )
        break

      default:
        break
    }
  }

  const progressHandler = useCallback(
    (progress: number) => {
      const onePartOfWholeProgress = +(100 / carousels.length).toFixed(2)
      const progressIndex = Math.round(progress / onePartOfWholeProgress)
      if (progressIndex === carousels.length) {
        return
      }
      slidePosHandler(progressIndex)
    },
    [instance, slidePosHandler],
  )

  function skipCarouselHandler() {
    if (scrollingBottom) {
      scrollController?.scroll(
        scrollController.start +
          window.innerHeight +
          (scrollController.end - scrollController.start),
      )
    } else {
      scrollController?.scroll(scrollController.start - window.innerHeight)
    }
  }

  return (
    <div>
      <section
        ref={yearCarouselRef}
        className="hidden xl:block bg-[##EFF3F4]  min-h-screen relative border-b-2 border-gray"
      >
        <div className="box-top border-r-2 border-gray h-screen absolute top-0 left-0"></div>
        <h3 className="font-medium px-3 py-6 xsl:py-7 2xl:py-12 text-center">
          Leaving a trail of success behind
        </h3>

        <section className="timeline-wrapper md:grid hidden border-t-2 border-gray border-solid ">
          {/* timeline */}
          <div className="flex flex-col gap-3 2xl:gap-5 border-r-2 border-gray border-solid blade-top-padding-sm blade-bottom-padding- ">
            {carousels.map((elem, index) => {
              const key = `${index}`
              return (
                <div className="relative" key={key}>
                  <div
                    className={`absolute -right-[7px] h-3 w-3 rounded-full bg-orange top-0 bottom-0 my-auto left-auto ${
                      index === activeSlide ? 'opacity-100' : 'opacity-0'
                    }`}
                  />
                  <button
                    onClick={() => setPosition(index)}
                    aria-label={elem.title}
                    className={`flex justify-end w-full pr-7 transition-all duration-[250] ease-in-out focus-visible:outline-orange ${
                      index === activeSlide
                        ? 'text-orange'
                        : 'text-black hover:text-opacity-60 text-opacity-40 focus-visible:text-opacity-60'
                    }`}
                  >
                    <span
                      className={`font-medium transition-all  ${
                        index === activeSlide
                          ? 'text-2xl 2xl:text-3xl'
                          : 'text-xl 2xl:text-2xl'
                      }`}
                    >
                      {elem.year}
                    </span>
                  </button>
                </div>
              )
            })}
          </div>

          {/* main content - desktop */}
          <div className="2xl:blade-top-padding 2xl:blade-bottom-padding">
            <Swiper
              ref={swiperRef}
              direction={'vertical'}
              modules={[Navigation, Pagination, A11y]}
              draggable={false}
              allowTouchMove={false}
              speed={600}
              className="max-h-[30rem] bg-[url('assets/about/mobile_bg.png')] bg-center bg-contain bg-no-repeat px-10 bg-origin-content"
            >
              {carousels.map((elem, index) => {
                const key = `${index}`
                return (
                  <SwiperSlide key={key} className="h-full">
                    <div className="flex flex-col h-full">
                      <div className="basis-80 grow-0 shrink-0 overflow-hidden">
                        <img
                          src={elem.coverImage}
                          className="h-full w-full object-contain"
                          alt={elem.title}
                        />
                      </div>
                      <div className="flex-0 px-4 text-center ">
                        <h4 className="font-medium mx-auto max-w-md 2xl:hidden block">
                          {elem.title}
                        </h4>
                        <h5 className="font-medium mx-auto 2xl:block hidden max-w-lg">
                          {elem.title}
                        </h5>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>

          {/* buttons */}
          <div>
            <div className="max-w-[200px] grid place-content-center h-full ">
              <div className="rounded-full flex flex-col self-center justify-between gap-10 border border-[#3C7BD6] m-auto px-2 py-2 sm:max-w-max mt-8">
                <button
                  aria-label="Previous slide"
                  disabled={activeSlide === 0}
                  onClick={() => {
                    scrollPosHandler('-')
                  }}
                  className="disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-[#3C7BD6] rounded-full outline-offset-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="512"
                    height="512"
                    viewBox="0 0 512 512"
                    className={`h-9 w-9 lg:h-8 xl:h-10 xl:w-10 lg:w-8 p-1.5 rotate-90 ${
                      activeSlide === 0
                        ? 'bg-none'
                        : 'bg-[#3C7BD6] hover:bg-[#69a4f7] active:bg-[#3C7BD6] rounded-full'
                    }`}
                  >
                    <path
                      fill="none"
                      stroke={`${activeSlide === 0 ? '#3C7BD6' : '#fff'}`}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="48"
                      d="M244 400L100 256l144-144M120 256h292"
                    />
                  </svg>
                </button>

                <button
                  aria-label="Next slide"
                  disabled={activeSlide === carousels.length - 1}
                  onClick={() => {
                    scrollPosHandler('+')
                  }}
                  className="disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-[#3C7BD6] rounded-full outline-offset-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="512"
                    height="512"
                    viewBox="0 0 512 512"
                    className={`h-9 w-9 lg:h-8 xl:h-10 xl:w-10 lg:w-8 rounded-full  -rotate-90 p-1.5  ${
                      activeSlide === carousels.length - 1
                        ? 'bg-none'
                        : 'bg-[#3C7BD6] hover:bg-[#69a4f7] active:bg-[#3C7BD6]'
                    }`}
                  >
                    <path
                      fill="none"
                      stroke={`${
                        activeSlide === carousels.length - 1
                          ? '#3C7BD6'
                          : '#fff'
                      }`}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="48"
                      d="M244 400L100 256l144-144M120 256h292"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* timeline skip button */}
        <button
          aria-label="Skip timeline"
          className="text-orange px-1 z-10 bottom-8 right-32 font-regular absolute text-2xl after:content-[''] after:h-[2px] after:w-20 after:bg-orange after:absolute after:left-[105%] after:top-1/2 outline-none opacity-80 hover:opacity-100 transition-all duration-200 focus-visible:opacity-100 focus-visible:outline-orange rounded-md"
          onClick={skipCarouselHandler}
        >
          Skip timeline
        </button>
      </section>
    </div>
  )
}
