import ComplianceChartOrganism, {
  ComplienceData,
} from 'organisms/complianceChart'

const chartData: ComplienceData = {
  th: ['Pressure (atm)', 'Balloon diameter (Ø mm)'],
  tableWidth: '',
  columnWidths: ['', ''],
  columnSpanStructure: [1, 9],
  td: [
    {
      text: [
        '',
        '1.75',
        '2.0',
        '2.25',
        '2.5',
        '2.75',
        '3.0',
        '3.25',
        '3.5',
        '4.0',
      ],
      textColor: [
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
      ],
      bgColor: [
        'bg-white',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
      ],
    },
    {
      text: [
        '2',
        '1.66',
        '1.87',
        '2.11',
        '2.36',
        '2.59',
        '2.82',
        '3.06',
        '3.29',
        '3.77',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
      ],
    },
    {
      text: [
        '4',
        '1.70',
        '1.94',
        '2.18',
        '2.43',
        '2.67',
        '2.91',
        '3.16',
        '3.39',
        '3.89',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
      ],
    },
    {
      text: [
        '6 NOM*',
        '1.75',
        '2.00',
        '2.25',
        '2.50',
        '2.75',
        '3.00',
        '3.25',
        '3.50',
        '4.00',
      ],
      textColor: [
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
      ],
      bgColor: [
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
      ],
    },
    {
      text: [
        '8',
        '1.80',
        '2.06',
        '2.32',
        '2.57',
        '2.83',
        '3.09',
        '3.34',
        '3.61',
        '4.11',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
      ],
    },
    {
      text: [
        '10',
        '1.84',
        '2.13',
        '2.39',
        '2.64',
        '2.91',
        '3.18',
        '3.44',
        '3.71',
        '4.23',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
      ],
    },
    {
      text: [
        '12',
        '1.89',
        '2.19',
        '2.45',
        '2.71',
        '2.98',
        '3.27',
        '3.53',
        '3.82',
        '4.34',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
      ],
    },
    {
      text: [
        '14 RBP**',
        '1.94',
        '2.25',
        '2.52',
        '2.78',
        '3.06',
        '3.36',
        '3.62',
        '3.92',
        '4.45',
      ],
      textColor: [
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
        'text-white',
      ],
      bgColor: [
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
        'bg-black',
      ],
    },
    {
      text: [
        '16',
        '1.98',
        '2.32',
        '2.59',
        '2.85',
        '3.14',
        '3.45',
        '3.72',
        '4.03',
        '4.57',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
        'bg-[#DADFE0]',
      ],
    },
    {
      text: [
        '18',
        '2.03',
        '2.38',
        '2.66',
        '2.92',
        '3.22',
        '3.54',
        '3.81',
        '4.14',
        '4.68',
      ],
      textColor: [
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
        'text-black',
      ],
      bgColor: [
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
        'bg-white',
      ],
    },
  ],
}

export function ComplianceChart() {
  return (
    <ComplianceChartOrganism
      heading="Compliance chart"
      bgColor="bg-[#F0F4F5]"
      bgGradient="bg-[url(assets/bg-overlay/green-ellipse-bg.webp)]"
      complience={chartData}
    />
  )
}
